import React, { useState, useEffect } from 'react';
import SkinnyHero from '@shared/SkinnyHero/SkinnyHero';
import DonateForm from '@components/DonateForm/DonateForm';
import { css } from '@utils';
import Styles from './DonatePage.module.scss';

const DonatePage = ({
  heroEyebrow,
  heroTitle,
  heroDesktopBackgroundImage,
  heroMobileBackgroundImage,
  donationQuoteCopy,
  donationQuoteAuthorName,
  donationQuoteAuthorTitle,
  donationBlockTopParagraph,
  mailingAddressLine1,
  mailingAddressLine2,
  mailingAddressCity,
  mailingAddressState,
  mailingAddressZipCode,
}) => {
  const [mounted, setMounted] = useState(false);

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return <div className={Styles.donatePage} />;
  }

  return (
    <div className={css(Styles.donatePage, imageLoaded && Styles.loaded)}>
      <SkinnyHero
        heroEyebrow={heroEyebrow}
        heroTitle={heroTitle}
        heroDesktopBackgroundImage={heroDesktopBackgroundImage}
        heroMobileBackgroundImage={heroMobileBackgroundImage}
        setImageLoaded={setImageLoaded}
        loaded={imageLoaded}
      />

      <div className={Styles.donationBody}>
        <div className={Styles.donationFormBody}>
          <DonateForm
            loaded={imageLoaded}
            donationBlockTopParagraph={donationBlockTopParagraph}
            donationQuoteCopy={donationQuoteCopy}
            donationQuoteAuthorName={donationQuoteAuthorName}
            donationQuoteAuthorTitle={donationQuoteAuthorTitle}
            mailingAddressLine1={mailingAddressLine1}
            mailingAddressLine2={mailingAddressLine2}
            mailingAddressCity={mailingAddressCity}
            mailingAddressState={mailingAddressState}
            mailingAddressZipCode={mailingAddressZipCode}
          />

          <div className={Styles.donationQuoteMobile}>
            <div className={Styles.quote}>
              <span className={Styles.startQuote}>“</span>
              <span className={Styles.donationQuoteCopy}>
                {donationQuoteCopy}
              </span>
              <span className={Styles.endQuote}>”</span>
            </div>
            <div className={Styles.authorCopy}>
              <div className={Styles.authorName}>{donationQuoteAuthorName}</div>
              <div className={Styles.authorTitle}>
                {donationQuoteAuthorTitle}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonatePage;
