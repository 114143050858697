import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import DonatePage from './DonatePage';

const DonatePageQuery = props => {
  const heroEyebrow = idx(props, _ => _.heroEyebrow);
  const heroTitle = idx(props, _ => _.heroTitle);

  const heroDesktopBackgroundImage = idx(
    props,
    _ => _.heroDesktopBackgroundImage
  );
  const heroMobileBackgroundImage = idx(
    props,
    _ => _.heroMobileBackgroundImage
  );

  const donationQuoteCopy = idx(props, _ => _.donationQuoteCopy);
  const donationQuoteAuthorName = idx(props, _ => _.donationQuoteAuthorName);
  const donationQuoteAuthorTitle = idx(props, _ => _.donationQuoteAuthorTitle);
  const donationBlockTopParagraph = idx(
    props,
    _ => _.donationBlockTopParagraph
  );

  const mailingAddressLine1 = idx(props, _ => _.mailingAddressLine1);
  const mailingAddressLine2 = idx(props, _ => _.mailingAddressLine2);
  const mailingAddressCity = idx(props, _ => _.mailingAddressCity);
  const mailingAddressState = idx(props, _ => _.mailingAddressState);
  const mailingAddressZipCode = idx(props, _ => _.mailingAddressZipCode);

  return (
    <DonatePage
      heroEyebrow={heroEyebrow}
      heroTitle={heroTitle}
      heroDesktopBackgroundImage={heroDesktopBackgroundImage}
      heroMobileBackgroundImage={heroMobileBackgroundImage}
      donationQuoteCopy={donationQuoteCopy}
      donationQuoteAuthorName={donationQuoteAuthorName}
      donationQuoteAuthorTitle={donationQuoteAuthorTitle}
      donationBlockTopParagraph={donationBlockTopParagraph}
      mailingAddressLine1={mailingAddressLine1}
      mailingAddressLine2={mailingAddressLine2}
      mailingAddressCity={mailingAddressCity}
      mailingAddressState={mailingAddressState}
      mailingAddressZipCode={mailingAddressZipCode}
    />
  );
};

export default DonatePageQuery;

export const query = graphql`
  fragment DonatePage on ContentfulDonationPage {
    heroEyebrow
    heroTitle
    heroDesktopBackgroundImage {
      description
      fluid(maxWidth: 1920, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroMobileBackgroundImage {
      description
      fluid(maxWidth: 1200, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    donationQuoteCopy
    donationQuoteAuthorName
    donationQuoteAuthorTitle
    donationBlockTopParagraph {
      childMarkdownRemark {
        html
      }
    }
  }
`;
