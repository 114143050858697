import React, { useState, useRef } from 'react';
import {
  css,
  getParameterByName,
  removeURLParameter,
  isIE,
  trackEvent,
} from '@utils';
import { CheckMark } from '@shared/Icons/Icons';
import Styles from './DonateForm.module.scss';

const DonateForm = ({
  loaded,
  donationBlockTopParagraph,
  donationQuoteCopy,
  donationQuoteAuthorName,
  donationQuoteAuthorTitle,
  mailingAddressLine1,
  mailingAddressLine2,
  mailingAddressCity,
  mailingAddressState,
  mailingAddressZipCode,
}) => {
  const [donationAmountOption, setDonationAmountOption] = useState(20);
  const [donationAmountOptionError, setDonationAmountOptionError] = useState(
    false
  );
  const showThankyou =
    getParameterByName('status', window.location.href) === 'thankyou';

  if (showThankyou) {
    const newUrl = removeURLParameter(window.location.href, 'status');
    window.history.replaceState(null, {}, newUrl);
  }

  const [manualAmountOption, setManualAmountOption] = useState('');
  const [donationName, setDonationName] = useState('');
  const [donationComment, setDonationComment] = useState('');

  const [makeMonthly, setMakeMonthly] = useState(false);
  const [makeMonthlyError, setMakeMonthlyError] = useState(false);

  const formRef = useRef(null);

  const getMailingAddressFormatted = () => {
    return (
      <>
        <div>The Leary Firefighter Foundation</div>

        <div>
          {`${mailingAddressLine1} ${
            mailingAddressLine2 ? `${mailingAddressLine2} ` : ''
          }`}
          <br />
          {` ${mailingAddressCity ? `${mailingAddressCity}, ` : ''}
            ${mailingAddressState ? `${mailingAddressState}, ` : ''}
            ${mailingAddressZipCode ? `${mailingAddressZipCode} ` : ''}`}
        </div>
      </>
    );
  };

  const formIsValid = () => {
    let valid = true;
    if (!manualAmountOption && !donationAmountOption) {
      valid = false;
    }

    return valid;
  };

  const validateForm = () => {
    let valid = true;
    if (!manualAmountOption && !donationAmountOption) {
      setDonationAmountOptionError(true);
      valid = false;
    }

    return valid;
  };

  const onSubmitButtonClick = () => {
    if (validateForm()) {
      // send
      trackEvent(
        {
          category: 'Donate',
          action: `https://www.paypal.com/cgi-bin/webscr`,
          label: 'Submit Donation',
        },
        {
          customDimension1: manualAmountOption || donationAmountOption,
          customDimension2: donationName,
          customDimension3: makeMonthly,
        }
      );
      formRef.current.submit();
    } else {
      // not valid
    }
  };

  const onDonationAmountClick = amount => {
    setDonationAmountOption(amount);
    setDonationAmountOptionError(false);
  };

  const onMonthlyCheckboxClick = () => {
    setMakeMonthly(!makeMonthly);
    setMakeMonthlyError(false);
  };

  const onDonationNameChange = e => {
    const newNameValue = e.target.value;
    setDonationName(newNameValue);
  };

  const onDonationCommentChange = e => {
    const newCommentValue = e.target.value;
    setDonationComment(newCommentValue);
  };

  const onManualAmountChange = e => {
    const newAmount = e.target.value;

    setManualAmountOption(newAmount);
    setDonationAmountOption(null);
    setDonationAmountOptionError(null);
  };

  const submitDisabled = !formIsValid();
  const getDonationName = () => {
    let _donationName = donationName
      ? `In Honor of ${donationName}`
      : 'General donation for the Foundation';

    if (donationComment) {
      _donationName = `${_donationName} - Notes: ${donationComment}`;
    }

    return _donationName;
  };
  return (
    <div className={css(Styles.donationFormBodyInner, loaded && Styles.loaded)}>
      <div
        className={css(
          Styles.topParagraph,
          showThankyou && Styles.showAfterThankyou
        )}
        dangerouslySetInnerHTML={{
          __html: donationBlockTopParagraph.childMarkdownRemark.html,
        }}
      />

      {showThankyou && (
        <div className={Styles.thankyouMessage}>
          Thank you very much for your support. Your contribution is greatly
          appreciated.
        </div>
      )}
      <div className={Styles.donationForm}>
        {/* This form is hidden and gets updated on submit */}
        <form
          ref={formRef}
          target="_blank"
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
        >
          {/* The hidden input value for 'cmd' is '_donations' for a single donation
              and '_Xclick-subscriptions' for a monthly subscription
            */}
          {makeMonthly ? (
            <>
              <input type="hidden" name="cmd" value="_xclick-subscriptions" />

              {/* These 3 hidden inputs were present on the original form submission
               of the previous site. Keeping for consistency. 
               */}
              <input type="hidden" name="p3" value="1" />
              <input type="hidden" name="t3" value="M" />
              <input type="hidden" name="src" value="1" />
            </>
          ) : (
            <input type="hidden" name="cmd" value="_donations" />
          )}

          <input type="hidden" name="currency_code" value="USD" />

          <input
            type="hidden"
            name="return"
            value={`${window.location.href}?status=thankyou`}
          />
          <input
            type="hidden"
            name="cancel_return"
            value={`${window.location.href}`}
          />
          <input
            type="hidden"
            name="business"
            value="operations@learyfirefighters.org"
          />

          {/* For monthly donations, the donation name needs
           to be appended to the 'item_option' field. 
          */}

          {!makeMonthly && (
            <input type="hidden" name="item_name" value={getDonationName()} />
          )}

          {makeMonthly && (
            <input type="hidden" name="item_name" value={getDonationName()} />
          )}

          {/* The dollar amount field has two inputs for a single donation: 'amount' and 'amount-preset'
              For a monthly subscription, the dollar amount has 1 field named 'a3'.
          */}
          {!makeMonthly && (
            <input
              type="hidden"
              name="amount-preset"
              value={
                manualAmountOption
                  ? `${manualAmountOption}`
                  : `${donationAmountOption}`
              }
            />
          )}

          {!makeMonthly && (
            <input
              type="hidden"
              name="amount"
              value={
                manualAmountOption
                  ? `${manualAmountOption}`
                  : `${donationAmountOption}`
              }
            />
          )}

          {makeMonthly && (
            <input
              type="hidden"
              name="a3"
              value={
                manualAmountOption
                  ? `${manualAmountOption}`
                  : `${donationAmountOption}`
              }
            />
          )}

          {/* <input type="hidden" name="item_number" value={donationName || ''} /> */}
        </form>

        <div
          className={css(
            Styles.donationFieldContainer,
            donationAmountOptionError && Styles.error
          )}
        >
          <div className={css(Styles.formLabel, Styles.donationAmountLabel)}>
            <span className={Styles.formLabelCopy}>
              Select Donation Amount:
            </span>
          </div>
          <ul className={Styles.donationAmountOptions}>
            <button
              type="button"
              className={css(
                Styles.donationAmount,
                donationAmountOption === 20 && Styles.active
              )}
              onClick={() => {
                onDonationAmountClick(20);
              }}
            >
              $20
            </button>
            <button
              type="button"
              className={css(
                Styles.donationAmount,
                donationAmountOption === 50 && Styles.active
              )}
              onClick={() => {
                onDonationAmountClick(50);
              }}
            >
              $50
            </button>
            <button
              type="button"
              className={css(
                Styles.donationAmount,
                donationAmountOption === 100 && Styles.active
              )}
              onClick={() => {
                onDonationAmountClick(100);
              }}
            >
              $100
            </button>
            <button
              type="button"
              className={css(
                Styles.donationAmount,
                donationAmountOption === 200 && Styles.active
              )}
              onClick={() => {
                onDonationAmountClick(200);
              }}
            >
              $200
            </button>
            <button
              type="button"
              className={css(
                Styles.donationAmount,
                donationAmountOption === 500 && Styles.active
              )}
              onClick={() => {
                onDonationAmountClick(500);
              }}
            >
              $500
            </button>
            <button
              type="button"
              className={css(
                Styles.donationAmount,
                donationAmountOption === 1000 && Styles.active
              )}
              onClick={() => {
                onDonationAmountClick(1000);
              }}
            >
              $1000
            </button>
          </ul>
        </div>

        <div className={Styles.donationFieldContainer}>
          <div className={css(Styles.formLabel, Styles.donationAmountLabel)}>
            <span className={Styles.formLabelCopy}>Or enter an amount</span>
            <span className={Styles.optionalLabel}>(optional)</span>
          </div>
          <div className={Styles.inputFieldWrapper}>
            <div className={Styles.placeholder}>$</div>
            <input
              type="number"
              min={0}
              className={css(Styles.textDonationInput, Styles.leftPadding)}
              value={manualAmountOption}
              onChange={onManualAmountChange}
            />
          </div>
        </div>

        <div className={Styles.donationFieldContainer}>
          <div className={css(Styles.formLabel, Styles.donationAmountLabel)}>
            <span className={Styles.formLabelCopy}>
              Donate in honor of someone
            </span>
            <span className={Styles.optionalLabel}>(optional)</span>
          </div>
          <div className={Styles.inputFieldWrapper}>
            <input
              className={Styles.textDonationInput}
              placeholder="Name"
              value={donationName}
              onChange={onDonationNameChange}
            />
          </div>
        </div>

        <div className={Styles.donationFieldContainer}>
          <div className={css(Styles.formLabel, Styles.donationAmountLabel)}>
            <span className={Styles.formLabelCopy}>
              Your comments are important to us. Please provide additional
              instructions or comments here.
            </span>
            <span className={Styles.optionalLabel}>(optional)</span>
          </div>
          <div className={Styles.inputFieldWrapper}>
            <input
              className={Styles.textDonationInput}
              placeholder="comments"
              value={donationComment}
              onChange={onDonationCommentChange}
            />
          </div>
        </div>

        <div
          className={css(
            Styles.donationFieldContainer,
            Styles.monthlyFieldContainer,
            makeMonthlyError && Styles.error
          )}
        >
          {isIE && <div className={Styles.ieFallbackCheckmark} />}
          <div
            className={css(
              Styles.monthlyCheckbox,
              isIE && Styles.isIE,
              makeMonthly && Styles.checked
            )}
            onClick={onMonthlyCheckboxClick}
          >
            <CheckMark iconStyles={Styles.checkMarkSvg} />
          </div>
          <div className={css(Styles.formLabel, Styles.donationAmountLabel)}>
            <span className={Styles.formLabelCopy}>
              Make this donation monthly
            </span>
            <span className={Styles.optionalLabel}>(optional)</span>
          </div>
        </div>
        <div
          className={css(
            Styles.submitButton,
            submitDisabled && Styles.disabled
          )}
          onClick={onSubmitButtonClick}
        >
          Submit Donation
        </div>
        <div className={Styles.mailingAddressContainer}>
          <div className={Styles.mailingAddressLabel}>
            Donations can also be mailed to:
          </div>
          <div className={Styles.mailingAddress}>
            {getMailingAddressFormatted()}
          </div>
        </div>
        <div>
          For additional comments or questions, please contact us at&nbsp;
          <a
            href="mailto:info@learyfirefighters.org"
            className={Styles.emailText}
          >
            info@learyfirefighters.org
          </a>
        </div>
      </div>
      <div className={Styles.donationQuoteDesktop}>
        <div className={Styles.quote}>
          <span className={Styles.startQuote}>“</span>
          <span className={Styles.donationQuoteCopy}>{donationQuoteCopy}</span>
          <span className={Styles.endQuote}>”</span>
        </div>
        <div className={Styles.authorCopy}>
          <div className={Styles.authorName}>{donationQuoteAuthorName}</div>
          <div className={Styles.authorTitle}>{donationQuoteAuthorTitle}</div>
        </div>
      </div>
    </div>
  );
};

export default DonateForm;
